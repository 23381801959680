//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LoadingSpinner from '@/components/LoadingSpinner'
export default {
    name: "Gallery",
    components: {
        LoadingSpinner,
        EmptyMessage: () => import('@/components/EmptyMessage'),
        Media: () => import('@/components/Gallery/Media')
    },
    data() {
      return {
        isLoading: false,
        isEmpty: false,
        isUploading: false,
        isUploadCancelled: true,
        currentUploadedCount: 0,
        currentProgress: 0,
        medias: [],
        pagination: 0,
        page: 1,
        files: null,
        isOnPreview: this.isPreview
      }
    },
    props: [
      'user_id',
      'entity_id',
      'isPreview',
      'allowUpload',
      'isOwner',
    ],
    created() {
        this.load();
    },
    methods: {
        /**
         * Removes a server deleted media on the application itself.
         */
        shiftMedias(id) {
            for (var i=0; i < this.medias.length; i++) {
                if (this.medias[i].id === id) {
                    this.medias.splice(i, 1);
                }
            }
        },
        getGallery(url) {
            this.isLoading = true;
            this.$http({
                url: url,
                method: "GET"
            }).then((response) => {
                if (response.data['status'] == 'gallery_retrieved') {
                    this.isEmpty = false;
                    response.data['body']['medias'].forEach((media) => {
                        this.medias.push(media);
                    });
                    this.pagination = response.data['body']['pagination'];
                }
                if (response.data['status'] == 'gallery_empty') {
                    this.isEmpty = true;
                }
                if (this.isOnPreview) {
                    this.medias = this.medias.splice(0, 10);
                    this.isOnPreview = false;
                }
            })
            .catch(err =>{
                return err
            })
            this.isLoading = false;            
        },
        /**
         * Loads user gallery depending on the supplied user_id on the prop.
         */
        loadUserGallery() {
            this.getGallery('profiles/' + this.user_id + '/gallery?page=' + this.page);
        },

        /**
         * Loads an entity gallery depending on the entity_id on the prop.
         */
        loadEntityGallery() {
            this.getGallery('entities/' + this.entity_id + '/gallery?page=' + this.page);
        },

        /**
         * Loads the images from the server.
         */
        load() {
            if (this.user_id) {
                this.loadUserGallery();
            }

            if (this.entity_id) {
                this.loadEntityGallery();
            }
        },
        loadMoreMedias() {
            this.page += 1;
            this.load();
        },

        /**
         * Send all the images to the send function.
         */
        uploadImages() {
            this.isUploading = true;
            if (this.numberOfFiles > 1) {
                this.files.forEach((file) => {
                    this.send(file);
                });
            } else {
                this.send(this.files[0]);
            }            
        },

        /**
         * Sends a file to the server.
         */
        send(file) {
            var formData = new FormData();

            if (this.entity_id) {
                formData.append('owner_id', this.entity_id);
            }

            // The file to upload.
            formData.append('file', file);

            this.$http({
                method: 'post',
                url: 'medias',
                data: formData,
                headers: { 'Content-Type': 'multipart/form-data' },
                onUploadProgress: function( progressEvent ) {
                    this.progress = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ))
                }.bind(this)
            }).then((response) => {
                if (response.data['status'] == 'file_uploaded') {
                    this.currentUploadedCount++;
                    this.currentProgress = 0;
                    this.medias.unshift(response.data['body']['media']);
                    if (this.medias.length > 20) {
                        this.medias.pop();  
                    }
                    if (this.currentUploadedCount >= this.numberOfFiles) {
                        this.isUploading = false; 
                        this.files = null;
                    }
                    location.reload()
                } else {
                    this.$q.notify({
                        message: 'An error has occurred while trying to upload.',
                        icon:'error'
                    });
                }
            });
        },
    },
    computed: {
        hasMoreMedias() {
            if (this.isEmpty) {
                return false;
            }
            if (this.pagination.maxNumberOfPages) {
                if (this.pagination.maxNumberOfPages > this.page) {
                    return true;
                }
            }
            return false;
        },
        numberOfFiles() {
          return Object.keys(this.files).length
        },
    },

}
